import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import {
  AuthContext,
  passwordRuleMessages,
  removeWhiteSpace,
  removeDashes,
  isValidMobile,
} from '@fifthdomain/fe-shared';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { chain, split } from 'lodash';
import RegisterPage1 from './RegisterPage1';
import RegisterPage2 from './RegisterPage2';
import RegisterPage3 from './RegisterPage3';
import RegisterPage4 from './RegisterPage4';
import RegisterPageError from './RegisterPageError';
import {
  getAssessmentIdFromSharedUrl,
  getOrgIdFromSharedUrl,
  getSharedLinkEvent,
} from '../../../shared/utils/urlUtils';

const Register = () => {
  const { handleSignUp, loading } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSignupError, setShowSignupError] = useState(false);

  const { search } = useLocation();
  const inviteToken = new URLSearchParams(search).get('inviteToken');
  const sharedLinkEvent = getSharedLinkEvent();

  // doing this because URLSearchParams removes special characters
  const searchEmail = chain(window.location.search)
    .replace('?', '')
    .split('&')
    .map((x) => split(x, '='))
    .value();

  const emailArr = searchEmail?.map((item) =>
    item?.indexOf('email') > -1 ? item[1] : emailArr,
  );

  const initialEmail = emailArr[1];

  const passwordValidationMessages = ({ value: password }) => {
    return passwordRuleMessages(password);
  };

  const initialValues = {
    name: '',
    email: initialEmail?.toLowerCase(),
    password: '',
    passwordConfirm: '',
    ageBracket: '',
    mobileNumber: '',
    newsSubscribeAgree: false,
    privacyPolicyAgree: false,
    termsOfServiceAgree: false,
    parentPrivacyPolicyAgree: false,
    parentTermsOfServiceAgree: false,
    parentNewsSubscribeAgree: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Full Name is required')
      .test(
        'noTrailing&LeadingWhitespace',
        'Name is not valid',
        (value) => value && value.length === value.trim().length,
      ),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .test(
        'passwordRules',
        passwordValidationMessages,
        (value) => value && passwordRuleMessages(value) === undefined,
      ),
    passwordConfirm: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('password')], 'Passwords do not match'),
    ageBracket: Yup.string().required('select an age range'),
    mobileNumber: Yup.string()
      .test(
        'countryCode',
        'Please include your country code (e.g., +61)',
        (value) => {
          if (value && value.trim() !== '') {
            return value.startsWith('+');
          }
          return true;
        },
      )
      .test(
        'validMobileNumber',
        'Please enter a valid mobile number',
        (value) => isValidMobile(value),
      ),
    newsSubscribeAgree: Yup.boolean(),
    privacyPolicyAgree: Yup.boolean().oneOf([true]),
    termsOfServiceAgree: Yup.boolean().oneOf([true]),
    parentNewsSubscribeAgree: Yup.boolean(),
    parentPrivacyPolicyAgree: Yup.boolean().oneOf([true]),
    parentTermsOfServiceAgree: Yup.boolean().oneOf([true]),
  });

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const watchAgeBracket = watch('ageBracket');
  const watchMobileNumber = watch('mobileNumber');

  const goBack = () => setCurrentPage(currentPage - 1);
  const goNext = () => setCurrentPage(currentPage + 1);
  const skip1Back = () => setCurrentPage(currentPage - 2);

  const requiredPageFields = {
    1: [
      'name',
      'password',
      'passwordConfirm',
      ...(sharedLinkEvent ? ['email'] : []),
    ],
    2: watchMobileNumber ? ['ageBracket', 'mobileNumber'] : ['ageBracket'],
    3: ['privacyPolicyAgree', 'termsOfServiceAgree'],
    4: ['parentPrivacyPolicyAgree', 'parentTermsOfServiceAgree'],
  };

  const containsErrors = (type) => {
    const fields = requiredPageFields[currentPage];
    return fields.some(
      (f) => Object.keys(errors)?.includes(f) && errors[f].type === type,
    );
  };
  const onPageComplete = async (doAction) => {
    setShowErrorAlert(true);
    const fields = requiredPageFields[currentPage];
    const complete = await trigger(fields);
    if (complete) {
      setShowErrorAlert(false);
      doAction();
    }
  };

  const onSubmit = async () => {
    const { name, email, password, ageBracket, mobileNumber } = getValues();
    const phone = mobileNumber
      ? removeDashes(removeWhiteSpace(mobileNumber))
      : undefined;

    const err = await handleSignUp({
      name,
      email: sharedLinkEvent
        ? email?.toLowerCase()
        : initialEmail?.toLowerCase(),
      password,
      ageBracket,
      mobileNumber: phone,
      inviteToken,
      assessmentId: getAssessmentIdFromSharedUrl(sharedLinkEvent),
      orgId: getOrgIdFromSharedUrl(),
    });
    const errorMessage = String(err).includes('PreSignUp failed')
      ? err?.substring(err.indexOf('Error:'))
      : err;
    setShowSignupError(errorMessage);
    if (err) {
      setCurrentPage(0);
    }
  };

  const validatePage2 = () => onPageComplete(() => goNext());
  const isUnderAge = ['Under 13', '13 to 15'].includes(watchAgeBracket);

  return (
    <Box width="500px" height="428px">
      <form>
        <Box>
          {
            {
              0: (
                <RegisterPageError
                  message={showSignupError}
                  onClick={() => setCurrentPage(1)}
                />
              ),
              1: (
                <RegisterPage1
                  Controller={Controller}
                  control={control}
                  validatePage={() => onPageComplete(goNext)}
                  showErrorAlert={showErrorAlert && containsErrors('required')}
                  sharedLinkEvent={sharedLinkEvent}
                />
              ),
              2: (
                <RegisterPage2
                  Controller={Controller}
                  control={control}
                  validatePage={validatePage2}
                  goBack={goBack}
                  showErrorAlert={showErrorAlert && containsErrors('required')}
                  errors={errors}
                />
              ),
              3: (
                <RegisterPage3
                  Controller={Controller}
                  control={control}
                  validatePage={() =>
                    onPageComplete(isUnderAge ? goNext : onSubmit)
                  }
                  goBack={goBack}
                  showPrivacyAlert={showErrorAlert && containsErrors('oneOf')}
                  loading={loading}
                />
              ),
              4: (
                <RegisterPage4
                  Controller={Controller}
                  control={control}
                  validatePage={() => onPageComplete(onSubmit)}
                  goBack={skip1Back}
                  showConsentAlert={showErrorAlert && containsErrors('oneOf')}
                  loading={loading}
                />
              ),
            }[currentPage]
          }
        </Box>
      </form>
    </Box>
  );
};

export default Register;
