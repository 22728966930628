import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Link } from '@mui/material';
import {
  FdSelect,
  FdTypography,
  FdCard,
  WHY_DO_WE_ASK_URL,
} from '@fifthdomain/fe-shared';
import { ButtonContainer, ButtonBack, ButtonNext } from './Buttons';
import { IncompleteAlert } from './Fragments';
import useStyles from '../styles';

const RegisterPage2 = ({
  Controller,
  control,
  validatePage,
  goBack,
  showErrorAlert,
}) => {
  const classes = useStyles();
  const ageBracket = ['Under 13', '13 to 15', '16+'];

  return (
    <FdCard heading="Create Account" elevation={24}>
      <Box>
        <FdTypography variant="subtitle1">Age Range</FdTypography>
        <Box display="flex" justifyContent="space-around">
          <Controller
            control={control}
            name="ageBracket"
            render={({
              field: { ref, value, ...fieldProps },
              fieldState: { error },
            }) => (
              <Box mr={1} width="100%">
                <FdSelect
                  fullWidth
                  id="ageBracket"
                  options={ageBracket}
                  defaultSelected={value}
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  width="140px"
                  placeholder="Age"
                  {...fieldProps}
                />
              </Box>
            )}
          />
        </Box>
        <Box mt={3} mb={2}>
          <FdTypography variant="body2">
            <Link
              href={WHY_DO_WE_ASK_URL}
              id="why-ask-info"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              Why do we ask this information?
            </Link>
          </FdTypography>
        </Box>
        {showErrorAlert && <IncompleteAlert />}
        <ButtonContainer>
          <ButtonBack onClick={goBack} />
          <ButtonNext onClick={validatePage} />
        </ButtonContainer>
      </Box>
    </FdCard>
  );
};

RegisterPage2.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  validatePage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.bool.isRequired,
  showUnderageAlert: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
};

export default RegisterPage2;
