/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import {
  signIn,
  signUp,
  signOut,
  resetPassword,
  updatePassword,
  confirmResetPassword,
  fetchAuthSession,
} from 'aws-amplify/auth';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import * as singleSpa from 'single-spa';
import {
  AuthContext,
  resetGlobalStore,
  setTheme,
  // eslint-disable-next-line import/no-unresolved
} from '@fifthdomain/fe-shared';
import {
  getAffiliatedParams,
  getAssessmentIdFromSharedUrl,
  getOrgIdFromSharedUrl,
  getSharedLinkEvent,
  getTypeFromUrl,
} from '../../shared/utils/urlUtils';

const AuthProvider = ({ children }) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const handleSignIn = useCallback(async (username, password) => {
    try {
      setLoading(true);
      // if already logged in sign out first
      await signOut();
      resetGlobalStore();
      setUser(null);
      const userData = await signIn({ username, password });
      const session = await fetchAuthSession();
      const userSessionData = {
        ...userData,
        signInUserSession: { accessToken: session?.tokens?.accessToken },
        email: session?.tokens?.idToken?.payload?.email,
        username: session?.tokens?.accessToken?.payload?.username,
      };
      setUser(userSessionData);
      setLoading(false);
      setError(false);

      const affiliatedParams = getAffiliatedParams();
      const sharedLinkEvent = getSharedLinkEvent(); // check if shared link

      if (sharedLinkEvent) {
        const assessmentId = getAssessmentIdFromSharedUrl(sharedLinkEvent);
        const orgId = getOrgIdFromSharedUrl();
        const productUrl =
          sharedLinkEvent === 'comp'
            ? '/competitions/comp'
            : '/assessor/assess';
        singleSpa.navigateToUrl(`${productUrl}/${assessmentId}/org/${orgId}`);
      } else if (affiliatedParams?.isAffiliated) {
        singleSpa.navigateToUrl(
          `/landing?isAffiliated=${affiliatedParams?.isAffiliated}&orgId=${affiliatedParams?.orgId}`,
        );
      } else {
        singleSpa.navigateToUrl('/landing');
      }
    } catch (err) {
      setError(
        JSON.stringify(err)?.includes('InvalidParameterException')
          ? 'Incorrect username or password'
          : err.message,
      );
      setLoading(false);
    }
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      resetGlobalStore();
      setUser(null);
      await signOut();
      setTheme('light');
      window.location.href = window.location.origin;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleForgotPassword = useCallback(async (username) => {
    try {
      setLoading(true);
      await resetPassword({
        username,
        options: {
          clientMetadata: {
            sourceUrl: window.location.origin,
          },
        },
      });
      setSuccess(true);
      setError(false);
      setLoading(false);
    } catch (err) {
      setSuccess(false);
      setError(err.message);
      setLoading(false);
    }
  }, []);

  const handleChangePassword = useCallback(async (oldPassword, newPassword) => {
    try {
      setLoading(true);
      await updatePassword({ oldPassword, newPassword });
      setLoading(false);
      setSuccess(true);
      setError(false);
    } catch (err) {
      setSuccess(false);
      setError(
        err.message === 'Incorrect username or password.'
          ? 'The current password you have entered is incorrect.'
          : err.message,
      );
      setLoading(false);
    }
  }, []);

  const handleResetPassword = useCallback(
    async (username, code, newPassword) => {
      if (!username || !code) {
        setError(
          'Use the reset password link from the email sent to your account.',
        );
        setSuccess(false);
      } else {
        try {
          setLoading(true);
          await confirmResetPassword({
            username,
            newPassword,
            confirmationCode: code,
          });

          setSuccess(true);
          setLoading(false);
        } catch (err) {
          setError(err?.message);
          setSuccess(false);
          setLoading(false);
        }
      }
    },
    [],
  );

  const handleSignUp = useCallback(
    async ({
      name,
      email,
      password,
      ageBracket,
      mobileNumber,
      inviteToken,
      assessmentId,
      orgId,
    }) => {
      try {
        setLoading(true);
        const sharedLinkEvent = getSharedLinkEvent(); // competition or assessment
        const type = getTypeFromUrl();
        const emailLowerCase = email?.toLowerCase();
        const affiliatedParams = getAffiliatedParams(); // affiliated parameters
        await signUp({
          username: emailLowerCase,
          password,
          options: {
            userAttributes: {
              name,
              email: emailLowerCase,
              phone_number: mobileNumber || '+61123456789',
              'custom:ageBracket': ageBracket,
              'custom:inviteToken': inviteToken || 'NO_INVITE_TOKEN',
            },
            clientMetadata: {
              invitationType: sharedLinkEvent ? 'SHARED_LINK' : 'EMAIL_INVITE',
              ...(sharedLinkEvent && { orgId }),
              ...(sharedLinkEvent && { assessmentId }),
              ...(type && { type }),
            },
          },
        });

        setLoading(false);
        const urlToLogin = sharedLinkEvent
          ? `/a/login/e/${sharedLinkEvent}/${assessmentId}/org/${orgId}?email=${emailLowerCase}`
          : affiliatedParams?.isAffiliated
            ? `/a/login?email=${emailLowerCase}&isAffiliated=${affiliatedParams?.isAffiliated}&orgId=${affiliatedParams?.orgId}`
            : `/a/login?email=${emailLowerCase}`;
        singleSpa.navigateToUrl(urlToLogin);

        return false;
      } catch (err) {
        setLoading(false);
        const errorMsg = err?.message ? err?.message : 'Error with signup';

        return errorMsg;
      }
    },
    [],
  );

  const authContextValue = useMemo(
    () => ({
      handleSignUp,
      handleSignIn,
      handleForgotPassword,
      handleResetPassword,
      handleSignOut,
      handleChangePassword,
      error,
      setError,
      success,
      setSuccess,
      loading,
      user,
      setUser,
    }),
    [
      handleSignUp,
      handleSignIn,
      handleForgotPassword,
      handleResetPassword,
      handleSignOut,
      handleChangePassword,
      error,
      setError,
      success,
      setSuccess,
      loading,
      user,
      setUser,
    ],
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
