import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Link, Checkbox } from '@mui/material';
import {
  FdCard,
  FdTypography,
  PRIVACY_POLICY_URL,
} from '@fifthdomain/fe-shared';
import { PrivacyLink, TOSLink, SubscribeText, ConsentAlert } from './Fragments';
import { ButtonBack, ButtonConsent, ButtonContainer } from './Buttons';

const RegisterPage4 = ({
  Controller,
  control,
  goBack,
  showConsentAlert,
  validatePage,
  loading,
}) => (
  <FdCard heading="Parental Consent" elevation={24}>
    <FdTypography variant="body2">
      <Box mt={1}>
        Your parent or guardian must read and agree to FifthDomain&aos;s
        policies before you can create your account.
      </Box>
      {`We collect personal
                information about our users. We use this information to improve
                their experience. We might also share this information with
                organisations including the user’s employer or education
                provider. Users have the right to access, correct, erase and
                make a complaint about their personal information. Our `}
      <Link
        href={PRIVACY_POLICY_URL}
        underline="always"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy.
      </Link>
      {` gives more details about how we use and protect user
                information.`}
    </FdTypography>
    <Box mt={2}>
      <Controller
        control={control}
        name="parentPrivacyPolicyAgree"
        render={({ field: { ref, value, ...fieldProps } }) => (
          <Box display="flex" alignItems="center">
            <Checkbox inputRef={ref} checked={value} {...fieldProps} />
            <PrivacyLink />
          </Box>
        )}
      />
    </Box>
    <Box mt={1}>
      <Controller
        control={control}
        name="parentTermsOfServiceAgree"
        render={({ field: { ref, value, ...fieldProps } }) => (
          <Box display="flex" alignItems="center">
            <Checkbox inputRef={ref} checked={value} {...fieldProps} />
            <TOSLink />
          </Box>
        )}
      />
    </Box>
    <Box mt={1}>
      <Controller
        control={control}
        name="parentNewsSubscribeAgree"
        render={({ field: { ref, value, ...fieldProps } }) => (
          <Box display="flex" alignItems="center">
            <Checkbox inputRef={ref} checked={value} {...fieldProps} />
            <SubscribeText />
          </Box>
        )}
      />
    </Box>
    {showConsentAlert && <ConsentAlert />}
    <ButtonContainer>
      <ButtonBack onClick={goBack} />
      <ButtonConsent onClick={validatePage} loading={loading} />
    </ButtonContainer>
  </FdCard>
);

RegisterPage4.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  validatePage: PropTypes.func.isRequired,
  showConsentAlert: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RegisterPage4;
