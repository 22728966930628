/* eslint-disable no-nested-ternary */
import React from 'react';
import { ThemeProvider } from '@mui/material';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { DEFAULT_THEME } from '@fifthdomain/fe-shared';
import AuthLayout from './components/auth/AuthLayout';
import AuthPageContainer from './components/auth/AuthPageContainer';
import GraphQlClient from './shared/GraphQlClient';
import AuthProvider from './components/auth/AuthProvider';
import './auth.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'auth',
  seed: 'auth',
});

const App = () => (
  <StylesProvider generateClassName={generateClassName}>
    <ThemeProvider theme={DEFAULT_THEME}>
      <GraphQlClient>
        <BrowserRouter>
          <AuthProvider>
            <AuthLayout>
              <Switch>
                <Route>
                  <AuthPageContainer />
                </Route>
              </Switch>
            </AuthLayout>
          </AuthProvider>
        </BrowserRouter>
      </GraphQlClient>
    </ThemeProvider>
  </StylesProvider>
);

export default App;
