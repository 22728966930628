import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from '@mui/material';
import { FdTextField, FdCard } from '@fifthdomain/fe-shared';
import { ButtonContainer, ButtonNext, ButtonSignIn } from './Buttons';
import { SubscribeText, IncompleteAlert } from './Fragments';
import useStyles from '../styles';

const RegisterPage1 = ({
  Controller,
  control,
  validatePage,
  showErrorAlert,
  sharedLinkEvent,
}) => {
  const classes = useStyles();

  return (
    <FdCard heading="Create Account" elevation={24}>
      <Controller
        control={control}
        name="name"
        render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
          <Box mt={1} mb={2}>
            <FdTextField
              id="signup_name"
              label="Full Name"
              required
              fullWidth
              autoComplete="off"
              error={!!error}
              helperText={error ? error.message : null}
              inputRef={ref}
              {...fieldProps}
            />
          </Box>
        )}
      />
      <Box mt={1} mb={2}>
        <Controller
          control={control}
          name="email"
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="email"
                label="Email Address"
                required
                fullWidth
                disabled={!sharedLinkEvent}
                error={!!error}
                helperText={error ? error.message : null}
                inputRef={ref}
                {...fieldProps}
              />
            </Box>
          )}
        />
      </Box>
      <Controller
        control={control}
        name="password"
        render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
          <Box mt={1} mb={2} className={classes.helpText}>
            <FdTextField
              id="password"
              label="Password"
              type="password"
              required
              fullWidth
              error={!!error}
              helperText={error ? error.message : null}
              inputRef={ref}
              {...fieldProps}
            />
          </Box>
        )}
      />
      <Controller
        control={control}
        name="passwordConfirm"
        render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
          <Box mt={1} mb={2}>
            <FdTextField
              id="signup_passwordConfirm"
              label="Confirm Password"
              type="password"
              required
              fullWidth
              error={!!error}
              helperText={error ? error.message : null}
              inputRef={ref}
              {...fieldProps}
            />
          </Box>
        )}
      />
      <Controller
        control={control}
        name="newsSubscribeAgree"
        render={({ field: { ref, value, ...fieldProps } }) => (
          <Box display="flex" alignItems="center">
            <Checkbox inputRef={ref} checked={value} {...fieldProps} />
            <SubscribeText />
          </Box>
        )}
      />
      {showErrorAlert && <IncompleteAlert />}
      <ButtonContainer>
        <ButtonSignIn sharedLinkEvent={sharedLinkEvent} />
        <ButtonNext onClick={validatePage} />
      </ButtonContainer>
    </FdCard>
  );
};

RegisterPage1.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  validatePage: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.bool.isRequired,
  sharedLinkEvent: PropTypes.string.isRequired,
};

export default RegisterPage1;
