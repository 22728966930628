import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FdButton } from '@fifthdomain/fe-shared';
import {
  getAssessmentIdFromSharedUrl,
  getOrgIdFromSharedUrl,
  getAffiliatedParams,
} from '../../../shared/utils/urlUtils';

export const ButtonContainer = ({ children }) => (
  <Box pt={2} display="flex" justifyContent="flex-end">
    {children}
  </Box>
);

ButtonContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ButtonSignIn = ({ sharedLinkEvent }) => {
  const history = useHistory();
  return (
    <FdButton
      id="cancel-btn"
      name="cancel_button"
      variant="tertiary"
      size="large"
      onClick={() => {
        if (sharedLinkEvent) {
          const assessmentId = getAssessmentIdFromSharedUrl(sharedLinkEvent);
          const orgId = getOrgIdFromSharedUrl();
          // shared link with competition/assessment id
          history.push(
            `/a/login/e/${sharedLinkEvent}/${assessmentId}/org/${orgId}`,
          );
          return;
        }
        const affiliatedParams = getAffiliatedParams();
        if (affiliatedParams?.isAffiliated) {
          history.push(
            `/a/login?email=${affiliatedParams?.email?.toLowerCase()}&isAffiliated=${
              affiliatedParams?.isAffiliated
            }&orgId=${affiliatedParams?.orgId}`,
          );
          return;
        }
        history.push('/a/login');
      }}
    >
      Sign in instead
    </FdButton>
  );
};

ButtonSignIn.propTypes = {
  sharedLinkEvent: PropTypes.bool.isRequired,
};

export const ButtonNext = ({ onClick }) => (
  <FdButton
    id="next-btn"
    name="next_button"
    onClick={onClick}
    size="large"
    style={{ marginLeft: '1rem' }}
  >
    Next
  </FdButton>
);

ButtonNext.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const ButtonBack = ({ onClick }) => (
  <FdButton
    id="back-btn"
    name="back_button"
    variant="secondary"
    onClick={onClick}
    size="large"
  >
    Back
  </FdButton>
);

ButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const ButtonAgree = ({ onClick, loading }) => (
  <FdButton
    id="create-button"
    name="create_button"
    onClick={onClick}
    size="large"
    style={{ marginLeft: '1rem' }}
  >
    {loading ? 'Loading...' : 'Agree'}
  </FdButton>
);

ButtonAgree.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export const ButtonConsent = ({ onClick, loading }) => (
  <FdButton
    id="consent-btn"
    name="consent_button"
    size="large"
    style={{ marginLeft: '1rem' }}
    onClick={onClick}
  >
    {loading ? 'Loading...' : 'Consent'}
  </FdButton>
);

ButtonConsent.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
