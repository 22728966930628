import React from 'react';
import { Box } from '@mui/material';
import { Route, Switch } from 'react-router-dom';
import { logos } from '@fifthdomain/fe-shared';
import useStyles from './styles';
import Login from './LogIn';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

function AuthPageContainer() {
  const { classes } = useStyles();

  const { FdLogoLight } = logos;
  return (
    <Box className="bg-white h-screen flex flex-col items-center justify-center">
      <Box display="flex" justifyContent="center">
        <img
          id="fd-inline-logo"
          src={FdLogoLight}
          alt="Fifth Domain logo text with tagline 'securing cyber skill' below"
          className={classes.authLogo}
        />
      </Box>
      <Box display="flex" justifyContent="center" p={4}>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/a/forgot-password" component={ForgotPassword} />
          <Route path="/a/reset-password" component={ResetPassword} />
          <Route path="/a/login" component={Login} />
          <Route path="/a/register" component={Register} />
        </Switch>
      </Box>
    </Box>
  );
}

export default AuthPageContainer;
