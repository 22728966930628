import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  authPage: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    maxWidth: '500px',
  },
  authLogo: {
    paddingBottom: '2rem',
    minHeight: '74px',
    maxWidth: '264px',
  },
  text: {
    margin: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 600,
    color: '#001339',
  },
  support: {
    position: 'fixed !important',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#001339 !important',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#001339 !important',
      color: '#fff !important',
    },
    height: '48px !important',
  },
  authImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    maxHeight: '100vh',
  },
  navButton: {
    marginLeft: '1rem',
  },
  link: {
    textDecoration: 'none',
    color: '#3333FF',
  },
  helpText: {
    '& div > div > p': {
      whiteSpace: 'pre-line',
    },
  },
  customHelpText: {
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    marginTop: '5px',
    marginLeft: '2px',
    fontSize: '0.75rem',
  },
}));

export default useStyles;
