import React, { useContext } from 'react';
import {
  InformationBox,
  FdCard,
  FdButton,
  AuthContext,
  passwordRuleMessages,
  FdTextField,
} from '@fifthdomain/fe-shared';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { Grid, Container, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    color: theme.palette.text.primary,
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = () => {
  const { classes } = useStyles();

  const { search } = useLocation();
  const confirmationCode = new URLSearchParams(search).get('confirmation_code');
  const username = new URLSearchParams(search).get('user_name');

  const passwordValidationMessages = ({ value: password }) => {
    return passwordRuleMessages(password);
  };

  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .test(
        'passwordRules',
        passwordValidationMessages,
        (value) => value && passwordRuleMessages(value) === undefined,
      ),
    passwordConfirm: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('password')], 'Passwords do not match'),
  });

  const { control, getValues, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    handleResetPassword,
    error: authError,
    success,
  } = useContext(AuthContext);

  const onSubmit = async () => {
    const { password } = getValues();
    await handleResetPassword(username, confirmationCode, password);
  };

  return (
    <Box width="500px" height="428px">
      <FdCard heading="Reset password" elevation={24}>
        <div className={classes.paper}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="password"
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <Box mb={1} className={classes.helpText}>
                      <FdTextField
                        id="password"
                        label="Password"
                        type="password"
                        required
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                        inputRef={ref}
                        {...fieldProps}
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="passwordConfirm"
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <Box mt={1} mb={1}>
                      <FdTextField
                        id="passwordConfirm"
                        label="Confirm Password"
                        type="password"
                        required
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                        inputRef={ref}
                        {...fieldProps}
                      />
                    </Box>
                  )}
                />
                <FdButton
                  type="submit"
                  color="primary"
                  className={classes.submit}
                  size="large"
                >
                  Reset
                </FdButton>
              </Grid>
            </Grid>
          </form>
          <Container component="main" maxWidth="sm">
            {authError && (
              <InformationBox
                variant="warning"
                status={authError}
                message="Reset your password"
                messageIsLink
                messageURL="/a/forgot-password"
              />
            )}
            {success && (
              <InformationBox
                status="Password reset successfully."
                variant="success"
                message="Sign in"
                messageIsLink
                messageURL="/"
              />
            )}
          </Container>
        </div>
      </FdCard>
    </Box>
  );
};

export default ResetPassword;
