import React from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { FdCard, FdTypography, FdButton } from '@fifthdomain/fe-shared';
import { ButtonContainer } from './Buttons';

const RegisterPageError = ({ message, onClick }) => (
  <FdCard
    heading={
      <Box display="flex" alignItems="center">
        <ReportProblemOutlinedIcon
          style={{ color: '#C62828', marginRight: '0.5rem' }}
        />
        <span>Registration Error</span>
      </Box>
    }
    elevation={24}
  >
    <FdTypography variant="body2">
      <Box mb={1}>{message}</Box>
    </FdTypography>
    <ButtonContainer>
      <FdButton
        id="create-button"
        name="create_button"
        onClick={onClick}
        size="large"
        style={{ marginLeft: '1rem' }}
      >
        Try Again
      </FdButton>
    </ButtonContainer>
  </FdCard>
);

RegisterPageError.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

RegisterPageError.defaultProps = {
  message: `Unfortunately there has been an error with your registration and your
  account has not been created. You will need to complete the registration
  process again.`,
};

export default RegisterPageError;
