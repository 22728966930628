/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AuthContext,
  InformationBox,
  FdCard,
  FdButton,
} from '@fifthdomain/fe-shared';
import { Typography, TextField, Link, Grid, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  getSharedLinkEvent,
  getAssessmentIdFromSharedUrl,
  getOrgIdFromSharedUrl,
  getAffiliatedParams,
} from '../../shared/utils/urlUtils';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    color: theme.palette.text.primary,
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotLink: {
    color: '#3333FF',
    textDecoration: 'underline',
  },
}));

const ForgotPassword = () => {
  const { classes } = useStyles();
  const [username, setUsername] = useState('');
  const sharedLinkEvent = getSharedLinkEvent(); // competition or assessment
  const affiliatedParams = getAffiliatedParams();

  const { handleForgotPassword, error, success, setSuccess, setError } =
    useContext(AuthContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    await handleForgotPassword(username?.toLowerCase());
  };

  useEffect(() => {
    setSuccess(false);
    setError('');
  }, [setError, setSuccess]);

  return (
    <Box width="500px" height="428px">
      <FdCard heading="Forgot password?" elevation={24}>
        <div className={classes.paper}>
          <Typography variant="body1">
            We&apos;ll send you a link to reset your password.
          </Typography>
          <Box my={2}>
            {(success || error) && (
              <InformationBox
                status="A reset link will be sent to the email address if it belongs to a registered account."
                variant="success"
                allowNullMessage
              />
            )}
          </Box>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Email Address"
                  name="username"
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
              </Grid>
            </Grid>
            <Box my={1}>
              <FdButton
                type="submit"
                color="primary"
                size="large"
                className={classes.submit}
              >
                Send Link
              </FdButton>
            </Box>
            <Grid container>
              <Grid item>
                <Link
                  to={
                    sharedLinkEvent
                      ? `/a/login/e/${sharedLinkEvent}/${getAssessmentIdFromSharedUrl(
                          sharedLinkEvent,
                        )}/org/${getOrgIdFromSharedUrl()}`
                      : affiliatedParams?.isAffiliated
                        ? `/a/login?email=${affiliatedParams?.email?.toLowerCase()}&isAffiliated=${
                            affiliatedParams?.isAffiliated
                          }&orgId=${affiliatedParams?.orgId}`
                        : '/a/login'
                  }
                  variant="body2"
                  component={RouterLink}
                  className={classes.forgotLink}
                >
                  Remember password? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </FdCard>
    </Box>
  );
};

export default ForgotPassword;
